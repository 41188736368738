import * as React from "react"
// import { graphql} from "gatsby"
// import { useRecoilState } from 'recoil'
// import { userLoginState, userInfoState } from "@status/UserState"
// import { Image } from "@components/atoms/Image"
// import { StaticImage } from "gatsby-plugin-image"
import Seo from "@components/atoms/Seo"
import * as Spacer from "@atoms/Spacer"
import Hero from "@blocks/MyPageHero"
import Birthday from "@blocks/MyPageBirthday"
import MemberId from "@blocks/MyPageMemberId"
// import Info from "@blocks/MyPageInfo"
import Special from "@blocks/MyPageSpecial"

import { Router } from "@reach/router"
import PrivateRoute from "@projects/PrivateRoute"
import { getUser } from "@src/services/auth.js"

function Content(){
    const user = getUser()
    if(!user) {
        return
    }
    return(
        <div>
            <Birthday data={{...user}}/>
            <MemberId data={{...user}} />
            {/* <Info data={{...user}} /> */}
            <Special data={{...user}} />
        </div>
    )
}

function MemberMyPage({data})
{
    const user = getUser()
    console.log(">>>>>>user", user)
    return (
        <>
            <Seo/>
            <Spacer.Header />
            <Hero/>
            <Router basepath="/member/profile">
                <PrivateRoute path="/" component={Content}/>
            </Router>
        </>
    )
}

// export const pagerQuery = graphql`
//     fragment Background on ImageSharp {
//         gatsbyImageData(
//             width: 1440
//             placeholder: BLURRED
//             formats: [AUTO, WEBP]
//             quality: 100
//         )
//     }
//     fragment Thumbnail on ImageSharp {
//         gatsbyImageData(
//             width: 1440
//             placeholder: BLURRED
//             formats: [AUTO, WEBP]
//             quality: 100
//         )
//     }
// `
export default MemberMyPage