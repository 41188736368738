import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Image } from "@atoms/Image"
import { StaticImage } from "gatsby-plugin-image"
import { Section, SectionHeader, SectionContent, SectionMoreLink, sectionCss } from "@layouts/Section"
import * as css from "@css/blocks/b-index-hero.module.styl"
import { TransitionLink } from "../atoms/Link"
import Border from "@atoms/Border"
import { AnchorList } from "@projects/AnchorList"

export default function MyPageHero() {
    const q = useStaticQuery(graphql`
        query {
            bg : file(relativePath: { eq: "IndexAbout__img__bg.jpg" }) { childImageSharp {
                ...Background
            } }
            main : file(relativePath: { eq: "IndexAbout__img__main.jpg" }) { childImageSharp {
                ...Thumbnail
            } }
        }
    `)
    //state: entry, payment, close
    return (
        <Section>
            <SectionHeader
                title="Profile"
                text="プロフィール"
            />
            <SectionContent isBorder={false}>
                <AnchorList data={[
                    {
                        title: '会員証',
                        to: null,
                        scroll: '#member-id',
                    },
                    // {
                    //     title: '会員情報',
                    //     to: null,
                    //     scroll: '#info',
                    // },
                    {
                        title: '会員情報の確認・変更',
                        to: process.env.GATSBY_MODD_URL_MYPAGE,
                        scroll: false,
                    }, {
                        title: '限定画像',
                        to: null,
                        scroll: '#special',
                    }
                ]}/>
            </SectionContent>
            {/* <SectionMoreLink
                title="More"
                text="もっとみる"
                to="/meet/"
            /> */}
        </Section>
    )
}
