import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import * as css from "@css/projects/p-special-list.module.styl"
// import Tags from "@projects/Tags"
import { Image } from "@atoms/Image"
// import { TransitionLink } from "../atoms/Link"
import DownloadSvg from "@images/Common__icn__download.svg"
import { TransitionLink } from "../atoms/Link"

function SpecialList({ currentStage = 0, data })
{
    const q = useStaticQuery(graphql`
        query {
            empty : file(relativePath: { eq: "Mypage__img__special-empty.png" }) { childImageSharp {
                ...Thumbnail
            } }
            logo : file(relativePath: { eq: "Common__logo__love-peace-2.svg" }) {
                publicURL
            }
        }
    `)
    let row = ({ stage, img, url, text }) => {
        console.log("===>>>!!!",img)
        if (currentStage >= stage && img) {
            return(
                <div className={css.aInner}>
                    <Image className={css.aImage} data={img.localFile.childImageSharp} />
                    <a className={css.aButton} href={img.localFile.publicURL}  download>
                        <DownloadSvg/>
                    </a>
                </div>
            )
        } else {
            return (
                <div className={css.aInner}>
                    <Image className={css.aImage} data={q.empty}/>
                    {/* <div className={css.aEmpty}>
                        <div className={css.aEmpty__inner}>
                            <div className={css.aLogo}>
                                <img src={q.logo.publicURL} alt="YU SHIROTA OFFICIAL FANCLUB LOVE&PEACE" />
                            </div>
                            <p className={css.aText}>{text}</p>
                        </div>
                    </div> */}
                </div>
            )
        }
    }

    return (
        <div className={css.pSpecialList}>
            <ul>
                {data.map( (d, i) => {
                    return (
                        <li key={i}>
                            {row(d)}
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export { SpecialList }