import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Image } from "@atoms/Image"
import { StaticImage } from "gatsby-plugin-image"
import { Section, SectionHeader, SectionContent, SectionMoreLink, sectionCss } from "@layouts/Section"
import * as css from "@css/blocks/b-index-hero.module.styl"
import { TransitionLink } from "../atoms/Link"
import Border from "@atoms/Border"
import { MemberId } from "@projects/MemberId"

export default function MyPageMemberId({data}) {
    const q = useStaticQuery(graphql`
        query {
            bg : file(relativePath: { eq: "IndexAbout__img__bg.jpg" }) { childImageSharp {
                ...Background
            } }
            main : file(relativePath: { eq: "IndexAbout__img__main.jpg" }) { childImageSharp {
                ...Thumbnail
            } }
        }
    `)
    //state: entry, payment, close
    return (
        <Section id="member-id">
            <SectionHeader
                title="MemberID"
                text="会員証"
            />
            <SectionContent>
                <MemberId data={data}/>
            </SectionContent>
            <SectionMoreLink
                title="Edit"
                text="会員情報の確認・変更"
                to={process.env.GATSBY_MODD_URL_EDIT}
            />
        </Section>
    )
}
