import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import * as css from "@css/atoms/a-question-button.module.styl"
import { TransitionLink } from "../atoms/Link"

export default function QuestionButton({label,to})
{
    const q = useStaticQuery(graphql`
        query {
            icon : file(relativePath: { eq: "Common__icn__question.svg" }) {
                publicURL
            }
        }
    `)
    return(
        <TransitionLink className={css.aQuestionButton} to={to}>
            {label}
            <span className={css.aIcon} style={ { backgroundImage: `url(${q.icon.publicURL})` }}/>
        </TransitionLink>
    )
}