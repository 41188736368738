import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import { Image } from "@atoms/Image"
// import { StaticImage } from "gatsby-plugin-image"
import { Section, SectionHeader, SectionContent, SectionMoreLink, sectionCss } from "@layouts/Section"
// import * as css from "@css/blocks/b-index-hero.module.styl"
// import { TransitionLink } from "../atoms/Link"
// import Border from "@atoms/Border"
import { SpecialList } from "@projects/SpecialList"
import { SpecialListHeader } from "@projects/SpecialListHeader"
import dayjs from "dayjs"

export default function MyPageSpecial({data}) {
    const q = useStaticQuery(graphql`
        fragment SpecialImg on ImageSharp{
            gatsbyImageData(
                width: 1280
                height: 1280
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                quality: 100
            )
        }
        query {
            bg : file(relativePath: { eq: "IndexAbout__img__bg.jpg" }) { childImageSharp {
                ...Background
            } }
            dummy : file(relativePath: { eq: "Dummy__img__06.jpg" }) { childImageSharp {
                ...Thumbnail
            } }
            stage01: wpSpecial(slug: {eq: "stage_01"}) {
                acf_special { image { localFile { 
                    publicURL
                    childImageSharp { ...SpecialImg } } } }
            }
            stage03: wpSpecial(slug: {eq: "stage_03"}) {
                acf_special { image { localFile {
                    publicURL
                    childImageSharp { ...SpecialImg } } } }
            }
            stage05: wpSpecial(slug: {eq: "stage_05"}) {
                acf_special { image { localFile {
                    publicURL
                    childImageSharp { ...SpecialImg } } } }
            }
            stage07: wpSpecial(slug: {eq: "stage_07"}) {
                acf_special { image { localFile {
                    publicURL
                    childImageSharp { ...SpecialImg } } } }
            }
            stage10: wpSpecial(slug: {eq: "stage_10"}) {
                acf_special { image { localFile {
                    publicURL
                    childImageSharp { ...SpecialImg } } } }
            }
        }
    `)
    //state: entry, payment, close
    const { Name1, Name2, Email, Zip, FcElements, Address1, Address2, Address3, Address4, Birthday, Gender, Email_Permission } = data
    let FcExpansionElement = FcElements.FcExpansionElement
    const { FcNo, FcStatus, FcJoinDate, FcExpDate, FcContinueDate, FcDuration, FcDurationUnit } = Array.isArray(FcExpansionElement) ? FcExpansionElement[0] : FcExpansionElement
    // const stage =  dayjs('2022/09/20').diff('2021/09/21','year')
    // console.log('test d', stage )
    const stage =  Number(FcDurationUnit) === 0 ? FcDuration : Math.floor(FcDuration / 12)
    console.log('mypage>>>', FcElements.FcExpansionElement)
    console.log('test d', stage, FcJoinDate, FcContinueDate, FcExpDate)
    return (
        <Section id="special">
            <SectionHeader
                title="Special"
                text="限定画像"
            />
            <SpecialListHeader />
            <SectionContent isBorder={false}>
                <SpecialList currentStage={stage} data={[
                    {
                        stage: 1,
                        img: q.stage01.acf_special.image,
                        url: '#',
                        text: 'ホワイト到達で解放',
                    },{
                        stage: 3,
                        img: q.stage03.acf_special.image,
                        url: '#',
                        text: 'シルバー到達で解放',
                    }, {
                        stage: 5,
                        img: q.stage05.acf_special.image,
                        url: '#',
                        text: 'ゴールド到達で解放',
                    }, {
                        stage: 7,
                        img: q.stage07.acf_special.image,
                        url: '#',
                        text: 'プラチナ到達で解放',
                    }, {
                        stage: 10,
                        img: q.stage10.acf_special.image,
                        url: '#',
                        text: 'ダイヤモンド到達で解放',
                    }
                ]}/>
            </SectionContent>
            {/* <SectionMoreLink
                title="More"
                text="もっとみる"
                to="/meet/"
            /> */}
        </Section>
    )
}
