import React from "react"
import * as css from "@css/projects/p-member-id.module.styl"
import { graphql, useStaticQuery } from "gatsby"
import { Image } from "@atoms/Image"
import { TransitionLink } from "../atoms/Link"
import QuestionButton from "@atoms/QuestionButton"

function MemberId({ data }) {
    const q = useStaticQuery(graphql`
        query {
            card_white : file(relativePath: { eq: "Common__img__card-white.png" }) { childImageSharp {
                ...BigCard
            } }
            card_silver : file(relativePath: { eq: "Common__img__card-silver.png" }) { childImageSharp {
                ...BigCard
            } }
            card_gold : file(relativePath: { eq: "Common__img__card-gold.png" }) { childImageSharp {
                ...BigCard
            } }
            card_plutina : file(relativePath: { eq: "Common__img__card-plutina.png" }) { childImageSharp {
                ...BigCard
            } }
            card_legend : file(relativePath: { eq: "Common__img__card-legend.png" }) { childImageSharp {
                ...BigCard
            } }
        }
    `)
    console.log(data)
    const { Name1, Name2, FcElements } = data
    let FcExpansionElement = FcElements.FcExpansionElement
    const { FcNo, FcJoinDate, FcExpDate, FcDuration } = Array.isArray(FcExpansionElement) ? FcExpansionElement[0] : FcExpansionElement
    let stage = Number(FcDuration)
    let id = FcNo
    let name = `${Name1} ${Name2}`
    let start = FcJoinDate
    let limit = FcExpDate
    let count = FcDuration
    let stageLabel 
    let card
    if( stage >= 0){
        stageLabel = 'ホワイトステージ'
        card = q.card_white
    }
    if( stage >= 3){
        stageLabel = 'シルバーステージ'
        card = q.card_silver
    }
    if( stage >= 5 ){
        stageLabel = 'ゴールドステージ'
        card = q.card_gold
    }
    if( stage >= 7 ){
        stageLabel = 'プラチナステージ'
        card = q.card_plutina
    }
    if( stage >= 10 ){
        stageLabel = 'ダイヤモンドステージ'
        card = q.card_legend
    }
    return (
        <div className={css.pMemberId}>
            <figure className={css.aCard}>
                <Image data={card}/>
            </figure>
            <div className={css.aDetail}>
                <div className={css.aStage}>
                    {stageLabel}
                    <QuestionButton label="特典" to="/member/profile/stage/"/>
                </div>
                <div className={css.aNumber}>
                    <span className={css.aLabel}>会員番号</span>
                    {id}
                </div>
                <div className={css.aName}>
                    <span className={css.aLabel}>氏名</span>
                    {name}
                </div>
                <div className={css.aLimit}>
                    <span className={css.aLabel}>会員期限</span>
                    {limit}　<span>[<TransitionLink to="/keizoku">会員継続案内</TransitionLink>]</span>
                </div>
                <div className={css.aCount}>
                    <span className={css.aLabel}>入会日</span>
                    <small>{start}</small>
                </div>
            </div>
        </div>
    )
}

export { MemberId }