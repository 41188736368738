import React from "react"
import * as css from "@css/projects/p-anchor-list.module.styl"
// import Tags from "@projects/Tags"
import { TransitionLink } from "@atoms/Link"

function AnchorList({ data }) {
    return (
        <div className={css.pList}>
            <ul>
                {data.map(({title, to, scroll}, i) => {
                    return (
                        <li key={i}>
                            <TransitionLink className={css.aInner} to={to} scroll={scroll}>
                                {title}
                                <span className={css.aArrow2}/>
                            </TransitionLink>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export { AnchorList }