import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Image } from "@atoms/Image"
// import { StaticImage } from "gatsby-plugin-image"
import { Section, SectionFigureBox } from "@layouts/Section"
// import * as css from "@css/blocks/b-index-hero.module.styl"
// import { TransitionLink } from "../atoms/Link"
// import Border from "@atoms/Border"
import { checkBirthday } from "@utility/CheckBirthday"

import { TransitionLink } from "../atoms/Link"

export default function MyPageBirtday({data}) {
    const [isActive, setIsActive] = React.useState(false)
    const [isOld, setIsOld] = React.useState(false)
    const q = useStaticQuery(graphql`
        query {
            bg : file(relativePath: { eq: "Mypage__img__birthday.jpg" }) { childImageSharp {
                ...Background
            } }
            birthday_2022v01: wpBirthday(acf_birthday: {id: {eq: "2022v01"}}) {
                ...Birthday
            }
            birthday_2023v01: wpBirthday(acf_birthday: {id: {eq: "2023v01"}}) {
                ...Birthday
            }
        }
    `)
    const { Birthday } = data
    React.useEffect(()=>{
        let { isWeekBirthday, isOldVersion } = checkBirthday(Birthday,7)
        // setIsActive( checkBirthday(Birthday,20).isNowBirthday )
        setIsActive( isWeekBirthday )
        setIsOld( isOldVersion )
    },[])
    console.log('birthday>>', isActive, Birthday, isOld) 
    const { uri, thumbnail, thumbnailFull} = q.birthday_2022v01
    const dom = ({ uri, thumbnail, thumbnailFull}) => {
        return(
            <SectionFigureBox>
                <TransitionLink to={`/member${uri}`}>
                    {thumbnailFull &&
                        <Image data={thumbnailFull.node.localFile} alt="" />
                    }
                </TransitionLink>
            </SectionFigureBox>
        )
    }
    return (
        <>
        { isActive &&
            <Section>
                { isOld ?
                    dom(q.birthday_2022v01)
                :
                    dom(q.birthday_2023v01)
                }
            </Section>
        }
        </>
    )
}
