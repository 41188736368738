// extracted by mini-css-extract-plugin
export var aButton1 = "Ff";
export var aButton2 = "Gf";
export var aButton3 = "Hf";
export var aButton4 = "If";
export var aButton5 = "Jf";
export var aButtonHeaderLogin = "Kf";
export var aButtonHeaderRegister = "Lf";
export var aButtonBig = "Mf";
export var aButtonRegister = "Nf";
export var aButtonLogin = "Of";
export var aButtonContinuation = "Pf";
export var icon = "Qf";
export var aButtonHeaderSpLogin = "Rf";
export var pSpecialListHeader = "Sf";
export var aThumbnail = "Tf";
export var aButton = "Uf";
export var aDetail = "Vf";
export var animationMarquee = "Wf";