// extracted by mini-css-extract-plugin
export var aButton1 = "Re";
export var aButton2 = "Se";
export var aButton3 = "Te";
export var aButton4 = "Ue";
export var aButton5 = "Ve";
export var aButtonHeaderLogin = "We";
export var aButtonHeaderRegister = "Xe";
export var aButtonBig = "Ye";
export var aButtonRegister = "Ze";
export var aButtonLogin = "_e";
export var aButtonContinuation = "af";
export var icon = "bf";
export var aButtonHeaderSpLogin = "cf";
export var aTagEntry = "df";
export var aTagPayment = "ef";
export var aTagClose = "ff";
export var aTagAnnounce = "gf";
export var aTagLottery = "hf";
export var aTagFree = "if";
export var aArrow1 = "jf";
export var aArrow2 = "kf";
export var pSpecialList = "lf";
export var aEmpty = "mf";
export var aInner = "nf";
export var aButton = "of";
export var aImage = "pf";
export var aLogo = "qf";
export var aText = "rf";
export var animationMarquee = "sf";