import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import * as css from "@css/projects/p-special-list-header.module.styl"
// import Tags from "@projects/Tags"
import { Image } from "@atoms/Image"
// import { TransitionLink } from "../atoms/Link"
import DownloadSvg from "@images/Common__icn__download.svg"
import { TransitionLink } from "../atoms/Link"

function SpecialListHeader()
{
    const q = useStaticQuery(graphql`
        query {
            main : file(relativePath: { eq: "Dummy__img__01.jpg" }) {
                publicURL
                childImageSharp {
                ...Thumbnail
            } }
        }
    `)

    return (
        <section className={css.pSpecialListHeader}>
            {/* <div className={css.aThumbnail}>
                <Image className={css.aImg} data={q.main} />
                <a className={css.aButton} href={q.main.publicURL} target="_blank" download>
                    <DownloadSvg />
                </a>
            </div> */}
            <div className={css.aDetail}>
                <p>
                    期間限定特典で付与される限定画像や、会員ステージに応じて付与される特別な画像、限定アイテムなど各特典が収められていきます。<br/>
                    ※一度退会されますと、継続年数はリセットとなり、それまでに取得された特典もご覧いただけなくなります。予めご了承ください。
                </p>
                {/* <p>
                    <TransitionLink className={css.aButton3} to="/special-content-detail/">詳しくはこちら</TransitionLink>
                </p> */}
            </div>
        </section>
    )
}

export { SpecialListHeader }