// extracted by mini-css-extract-plugin
export var aArrow1 = "tf";
export var aArrow2 = "uf";
export var pMemberId = "vf";
export var aCard = "wf";
export var aDetail = "xf";
export var aStage = "yf";
export var aNumber = "zf";
export var aName = "Af";
export var aLimit = "Bf";
export var aCount = "Cf";
export var aLabel = "Df";
export var animationMarquee = "Ef";